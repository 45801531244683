/** @jsx jsx */
import React from 'react';
import { jsx, Box, Flex, Themed } from 'theme-ui';
import { Card, Link } from '@sprinklr/shared-lib';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { removeNodeLocaleFromSlug } from '@sprinklr/shared-lib/utils/removeNodeLocaleFromSlug';
import { getPostPageSlug } from '@sprinklr/shared-lib/utils/getPostPageSlugs';
import setTextTruncate from '@sprinklr/shared-lib/utils/setTextTruncate';
import { useResponsiveValue } from '@theme-ui/match-media';
import { styleVariants, sharedStyles } from './styles';
import { getLocalizedDate } from '@sprinklr/shared-lib/utils/getLocalizedDate';
import { PageCardProps } from './types';
import PageCardCta from './components/PageCardCta';
import PageCardBlogInfo from './components/PageCardBlogInfo';
import PageCardCategory from './components/PageCardCategory';
import PageCardImage from './components/PageCardImage';

const PageCard: React.FC<PageCardProps> = props => {
  const {
    slug,
    slugPrefix,
    cardTitle,
    cardDescription,
    cardImage,
    publishDate,
    pageReferenceType,
    category,
    blogAuthors,
    node_locale,
    minutesToRead,
    ctaLabel,
    alwaysShowDescription,
    variant,
    pageCardSx,
  } = props;

  const { t } = useI18next();
  const localizedDate = getLocalizedDate(publishDate, node_locale);

  const href = removeNodeLocaleFromSlug(
    getPostPageSlug(slug, slugPrefix, node_locale),
  );

  const {
    cardSx,
    imageSx,
    bodySx,
    titleContainerSx,
    titleSx,
    descriptionContainerSx,
  } = styleVariants[variant];
  const { linkStyles, cardStyles, descriptionStyles } = sharedStyles;

  let descriptionContainerDisplay = [
    '-webkit-box',
    '-webkit-box',
    '-webkit-box',
    '-webkit-box',
  ];
  if (variant === 'horizontalShadow' && !alwaysShowDescription)
    descriptionContainerDisplay = [
      '-webkit-box',
      '-webkit-box',
      '-webkit-box',
      'none',
    ];

  const isMobile = useResponsiveValue([true, false]);

  const shouldDisplayImage =
    variant === 'verticalShadow' || variant === 'horizontalClean';
  const shouldDisplayDescription =
    !!(variant === 'verticalShadow' && cardDescription) ||
    !!(variant === 'horizontalShadow' && cardDescription) ||
    !!(alwaysShowDescription && cardDescription);

  return (
    <Link
      key={slug}
      href={href}
      styledLinkSx={linkStyles}
      ariaLabel={cardTitle}
    >
      <Card
        cardSx={{
          ...cardStyles,
          ...cardSx,
          ...pageCardSx,
        }}
      >
        {shouldDisplayImage && (
          <PageCardImage
            cardImage={cardImage}
            cardTitle={cardTitle}
            imageSx={imageSx}
          />
        )}
        <Flex
          sx={{
            height: '100%',
            flexDirection: 'column',
            ...bodySx,
          }}
        >
          {category && <PageCardCategory category={t(category)} />}
          <Box sx={{ ...setTextTruncate('2'), ...titleContainerSx }}>
            <Themed.h6
              as="p"
              sx={{ lineHeight: ['25px', '28px', '28px', '28px'], ...titleSx }}
            >
              {cardTitle}
            </Themed.h6>
          </Box>
          {shouldDisplayDescription && (
            <Box
              sx={{
                ...setTextTruncate('3'),
                ...descriptionContainerSx,
                display: descriptionContainerDisplay,
              }}
            >
              <Themed.p sx={descriptionStyles}>{cardDescription}</Themed.p>
            </Box>
          )}
          <Box sx={{ mt: ['auto'] }}>
            {pageReferenceType === 'Blog' && variant != 'horizontalShadow' && (
              <PageCardBlogInfo
                blogAuthors={blogAuthors}
                minutesToRead={minutesToRead}
                variant={variant}
                isMobile={isMobile}
                localizedDate={localizedDate}
              />
            )}
            <PageCardCta ctaLabel={t(ctaLabel)} />
          </Box>
        </Flex>
      </Card>
    </Link>
  );
};

export default PageCard;
