/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import ContentHubLayout from '../components/ContentHubLayout';
import LayoutSection from '@sprinklr/website/src/templates/LayoutSection';
import ContentHubTopicsContentColumns from '../components/ContentHubTopicsContentColumns';
import { getSharedHeroLayoutSectionProps } from '../utils/getSharedHeroLayoutSectionProps';
import { ContentHubBreadcrumbItems, ContentHubTopicsPageProps } from '../types';
import { useMemo } from 'react';

export const contentHubTopicsPageQuery = graphql`
  query (
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $language: String!
    $translationLocale: String!
  ) {
    ...HeaderFooterSocialQuery
    ...LocalesQuery
    ...ContentHubTopicsPageQueryFragment
    ...SiteMetadataQuery
    allContentfulMetadata(
      filter: {
        hardCodedPageName: { eq: "CONTENT_HUB_TOPICS" }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...ContentfulMetadataFragment
      }
    }
    heroMedia: allContentfulMedia(
      filter: {
        uniqueMediaUseCaseId: { eq: "CONTENT_HUB_HERO_BG" }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ... on ContentfulMedia {
          asset {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const ContenthubTopicsPageTemplate: React.FC<ContentHubTopicsPageProps> = ({
  data,
  pageContext,
}) => {
  const {
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    allContentfulContentHubPostPage,
    allContentfulMetadata,
    heroMedia,
    site,
  } = data;

  const { t } = useTranslation();

  const metadata = allContentfulMetadata?.nodes[0];
  const layoutProps = getSharedHeroLayoutSectionProps(t('All Topics'), null);
  const heroBg = heroMedia?.nodes[0]?.asset?.file?.url;

  const { nodeLocale } = pageContext;

  const breadcrumbItems: ContentHubBreadcrumbItems = useMemo(() => {
    return [
      {
        label: t('Home'),
        link: '/',
      },
      {
        label: t('CXM Guide'),
        link: nodeLocale === 'en-US' ? '/cxm' : undefined,
      },
      {
        label: t('All Topics'),
      },
    ];
  }, [nodeLocale]);

  return (
    <ContentHubLayout
      context={pageContext}
      slug={'/cxm'}
      metadata={metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
      pageCategory={t('All Topics')}
      nodeLocale={pageContext.nodeLocale}
      breadcrumbItems={breadcrumbItems}
    >
      <LayoutSection
        {...layoutProps}
        layoutSectionSx={{ backgroundImage: [`url(${heroBg})`] }}
        className={'scn-cxm-topics-page-hero'}
      />
      <ContentHubTopicsContentColumns
        posts={allContentfulContentHubPostPage}
        pageContext={pageContext}
      />
    </ContentHubLayout>
  );
};

export default ContenthubTopicsPageTemplate;
