export const getPageRange = ({
  totalPages,
  currentPage,
  maxRangeSize,
}: {
  totalPages: number;
  currentPage: number;
  maxRangeSize: number;
}): [number, number] => {
  if (totalPages <= maxRangeSize) {
    return [1, totalPages];
  }

  let startPage: number, endPage: number;
  const noOfCellsTillCurrentPage = Math.floor((maxRangeSize + 2) / 2);
  const noOfCellsAfterCurrentPage = maxRangeSize - noOfCellsTillCurrentPage;

  if (currentPage <= noOfCellsTillCurrentPage) {
    startPage = 1;
    endPage = maxRangeSize;
  } else if (currentPage + noOfCellsAfterCurrentPage >= totalPages) {
    startPage = totalPages - maxRangeSize + 1;
    endPage = totalPages;
  } else {
    startPage = currentPage - noOfCellsTillCurrentPage + 1;
    endPage = currentPage + noOfCellsAfterCurrentPage;
  }

  return [startPage, endPage];
};
