/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import ArrowIcon from '@sprinklr/shared-lib/assets/svgs/arrow.svg';
import { PageCardCtaProps } from '../types';

const PageCardCta: React.FC<PageCardCtaProps> = props => {
  const { ctaLabel } = props;

  return (
    <Box
      sx={{
        display: 'inline',
        verticalAlign: 'middle',
        svg: {
          position: 'relative',
          top: [null, '2px'],
          ml: '8px',
          height: ['12px', '14px'],
        },
      }}
    >
      <Box as="cta-label">
        {ctaLabel}
        <ArrowIcon />
      </Box>
    </Box>
  );
};

export default PageCardCta;
