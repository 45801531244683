/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { PageCardCategoryProps } from '../types';

const PageCardCategory: React.FC<PageCardCategoryProps> = props => {
  const { category } = props;

  return (
    <Box>
      <span
        sx={{
          p: ['4px 8px'],
          background: '#F1F1F1',
          color: '#646470',
          borderRadius: '4px',
          fontSize: 0,
          lineHeight: '15px',
          display: 'inline-block',
          fontWeight: 'semiBold',
          mb: ['12px', '16px'],
        }}
      >
        {category}
      </span>
    </Box>
  );
};

export default PageCardCategory;
