/** @jsx jsx */
import PageCard from '@sprinklr/website/src/templates/pageCardDisplayTemplate/PageCard';
import { jsx } from 'theme-ui';
import { ContentHubPostCardProps } from '../types';
import { removeLineBreaks } from '@sprinklr/website/contentHubSrc/utils';

const ContentHubPostCard: React.FC<ContentHubPostCardProps> = props => {
  const {
    contentHubPostCategory,
    contentHubPostHeadline,
    contentHubPostSubheadline,
    node_locale,
    slug,
    alwaysShowDescription,
    contentHubPostCardSx,
  } = props;

  return (
    <PageCard
      category={contentHubPostCategory}
      cardTitle={removeLineBreaks(contentHubPostHeadline)}
      cardDescription={contentHubPostSubheadline}
      slug={slug}
      slugPrefix="cxm"
      alwaysShowDescription={alwaysShowDescription}
      variant="horizontalShadow"
      ctaLabel="Read more"
      node_locale={node_locale}
      pageCardSx={contentHubPostCardSx}
    />
  );
};

export default ContentHubPostCard;
