/** @jsx jsx */
import { ReactElement } from 'react';
import { Flex, jsx } from 'theme-ui';
import { Link } from '@sprinklr/shared-lib';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { usePagination } from './usePagination';
import { getPageNumberStyles, getPaginationButtonStyles } from './styles';
import { getPaginatedSlugForPage, getPaginationSlugsAndNumber } from '../../utils/getPaginatedSlugs';
import Chevron from '@sprinklr/shared-lib/assets/svgs/chevron.svg';

export default function PaginationController({
  pageContext,
  anchorTagId,
  queryString,
  numberOfPaginatedPagesOverride,
}: {
  pageContext: any;
  anchorTagId?: string;
  queryString?: string;
  numberOfPaginatedPagesOverride?: number;
}): ReactElement {
  const { numberOfPaginatedPages, paginatedPath, paginatedPageNumber } = pageContext;
  const displayNumberOfPaginatedPages = numberOfPaginatedPagesOverride || numberOfPaginatedPages;
  const { backSlug, forwardSlug } = getPaginationSlugsAndNumber(
    paginatedPath,
    displayNumberOfPaginatedPages,
    paginatedPageNumber,
    anchorTagId,
    queryString
  );
  const { t } = useI18next();

  const isMinimal = useResponsiveValue([true, false, false]);
  const maxCellsToShow = useResponsiveValue([5, 5, 10]);

  const paginationRange = usePagination({
    totalPages: displayNumberOfPaginatedPages,
    currentPage: paginatedPageNumber,
    maxCellsToShow,
  });

  return (
    <Flex sx={{ justifyContent: 'center', width: '100%', gap: 2 }} className={'scn-pagination-controller'}>
      <Link href={backSlug} sx={getPaginationButtonStyles({ isDisabled: !backSlug, isMinimal })}>
        <Chevron sx={{ transform: 'rotate(90deg)' }} />
        {isMinimal ? <Chevron sx={{ transform: 'rotate(90deg)' }} /> : t('Previous')}
      </Link>
      <Flex sx={{ justifyContent: 'center', gap: 2 }}>
        {paginationRange.map(pageNumber => (
          <Link
            key={pageNumber}
            href={getPaginatedSlugForPage(paginatedPath, pageNumber, anchorTagId, queryString)}
            sx={getPageNumberStyles({ isSelected: pageNumber === paginatedPageNumber })}
          >
            {pageNumber}
          </Link>
        ))}
      </Flex>
      <Link href={forwardSlug} sx={getPaginationButtonStyles({ isDisabled: !forwardSlug, isMinimal })}>
        {isMinimal ? <Chevron sx={{ transform: 'rotate(270deg)' }} /> : t('Next')}
        <Chevron sx={{ transform: 'rotate(270deg)' }} />
      </Link>
    </Flex>
  );
}
