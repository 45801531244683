/** @jsx jsx */
import React, { useState } from 'react';
import { Grid, Box, Flex, Themed, jsx } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { StickyElement } from '@sprinklr/shared-lib';
import ContentHubPostCard from './ContentHubPostCard';
import ContentHubFilterForm from './ContentHubFilterForm';
import { ContentHubTopicsContentColumnsProps } from '../types';
import PaginationController from '@sprinklr/shared-lib/components/paginationController';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';
import { useTranslation } from 'react-i18next';

const ContentHubTopicsContentColumns: React.FC<
  ContentHubTopicsContentColumnsProps
> = props => {
  const { posts, pageContext } = props;

  const { cardsPerPage, cardsToSkip } = pageContext;
  const { t } = useTranslation();
  const postsArray = posts.nodes;

  const [sortedPosts, setSortedPosts] = useState(postsArray);

  const slicedPosts = sortedPosts.slice(
    cardsToSkip,
    cardsToSkip + cardsPerPage,
  );
  const numberOfPaginatedPages = Math.ceil(sortedPosts.length / cardsPerPage);

  let queryString;
  if (isWindowDefined()) {
    queryString = window.location.search;
  }

  const filterProps = {
    postsArray,
    pageContext,
    setSortedPosts,
    filteredPostCount: sortedPosts.length,
  };

  const PostCardDisplay = () => {
    return (
      <React.Fragment>
        {slicedPosts?.length > 0 ? (
          slicedPosts?.map((post, i) => {
            return (
              <ContentHubPostCard
                key={i}
                {...post}
                alwaysShowDescription={true}
                contentHubPostCardSx={{ mb: ['24px', '28px', null, '30px'] }}
              />
            );
          })
        ) : (
          <Themed.h4>
            {t(
              "We didn't find any matches. Try changing or removing the category to find more results.",
            )}
          </Themed.h4>
        )}
      </React.Fragment>
    );
  };

  return (
    <Container>
      <Grid
        sx={{
          height: '100%',
          gridTemplateColumns: ['1fr', null, null, '1fr 250px'],
          columnGap: '100px',
          rowGap: [4, '40px'],
        }}
        className={'scn-cxm-topics-content'}
      >
        <Box
          sx={{
            minWidth: [null, null, null, '250px'],
            order: [null, null, null, 2],
          }}
          className={'scn-cxm-topics-filter'}
        >
          <StickyElement stickyElementSx={{ top: ['195px'] }}>
            <ContentHubFilterForm {...filterProps} />
          </StickyElement>
        </Box>
        <Box className={'scn-cxm-topics-cards'}>
          <Flex
            sx={{
              flexDirection: 'column',
              mb: ['-24px', '-28px', null, '-30px'],
            }}
          >
            <PostCardDisplay />
          </Flex>
        </Box>
      </Grid>
      <Container>
        {sortedPosts?.length > 10 && (
          <PaginationController
            pageContext={pageContext}
            queryString={queryString}
            numberOfPaginatedPagesOverride={numberOfPaginatedPages}
          />
        )}
      </Container>
    </Container>
  );
};

export default ContentHubTopicsContentColumns;
