import { ThemeUIStyleObject } from 'theme-ui';

export const getPageNumberStyles = ({ isSelected }: { isSelected?: boolean }): ThemeUIStyleObject => ({
  p: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  minWidth: '40px',
  fontSize: [1, 2],
  border: 'none',

  color: isSelected ? '#fff' : 'black',
  backgroundColor: isSelected ? 'primary' : 'muted',
  pointerEvents: isSelected ? 'none' : 'auto',
  '&:hover, &:active, &:focus': {
    opacity: '28%',
    backgroundColor: '#1C6CFD',
    color: '#fff',
    border: 'none',
  },
});

export const getPaginationButtonStyles = ({ isDisabled, isMinimal }: { isDisabled?: boolean; isMinimal?: boolean }): ThemeUIStyleObject => ({
  p: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  backgroundColor: 'formFieldGrey',
  fontSize: [1, 2],
  border: 'none',

  gap: isMinimal ? 0 : 2,
  minWidth: isMinimal ? '40px' : '100px',

  color: isDisabled ? 'grey' : 'black',
  pointerEvents: isDisabled ? 'none' : 'auto',

  '&:hover, &:active, &:focus': {
    opacity: '28%',
    backgroundColor: isDisabled ? 'formFieldGrey' : '#1C6CFD',
    color: isDisabled ? 'grey' : '#fff',
    border: 'none',
  },
  svg: {
    height: '10px',
    width: '10px',
    path: { fill: isDisabled ? 'grey' : 'black' },
  },
  '&:hover > svg': {
    path: { fill: isDisabled ? 'grey' : '#fff' },
  },
});
