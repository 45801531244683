// libs
import { useMemo } from 'react';
import _range from 'lodash/range';

// helpers
import { getPageRange } from './helper';

type UsePaginationProps = {
  totalPages: number;
  currentPage: number;
  maxCellsToShow: number;
};

export const usePagination = ({ totalPages, currentPage, maxCellsToShow }: UsePaginationProps) =>
  useMemo(() => {
    const [startPage, endPage] = getPageRange({ totalPages, currentPage, maxRangeSize: maxCellsToShow });

    return _range(startPage, endPage + 1);
  }, [totalPages, currentPage, maxCellsToShow]);
