/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from 'theme-ui';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { PageCardBlogInfoProps } from '../types';

const PageCardBlogInfo: React.FC<PageCardBlogInfoProps> = props => {
  const { blogAuthors, minutesToRead, variant, isMobile, localizedDate } =
    props;
  const { t } = useI18next();

  return (
    <p
      sx={{
        fontSize: ['14px', '16px'],
        fontWeight: 'regular',
        mb: '6px',
        mt: '4px',
      }}
    >
      {blogAuthors?.map((blogAuthor, i) => (
        <Fragment key={i}>
          <span sx={{ fontWeight: 'semiBold' }}>
            {blogAuthor?.person?.authorName}
          </span>
          <span>{i !== blogAuthors.length - 1 ? ', ' : ''}</span>
        </Fragment>
      ))}
      {isMobile || variant === 'verticalShadow' ? (
        <br />
      ) : (
        <Fragment>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</Fragment>
      )}
      {localizedDate}
      {minutesToRead && (
        <Fragment>
          <span aria-hidden="true">&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
          {minutesToRead} {t('min read')}
        </Fragment>
      )}
    </p>
  );
};

export default PageCardBlogInfo;
